@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap');
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

// scss-docs-start gray-color-variables
$gray-100-custom: #f8f9fa !default;
$gray-200-custom: #e9ecef !default;
$gray-300-custom: #dee2e6 !default;
$gray-400-custom: #ced4da !default;
$gray-500-custom: #adb5bd !default;
$gray-600-custom: #6c757d !default;
$gray-700-custom: #495057 !default;
$gray-800-custom: #343a40 !default;
$gray-900-custom: #212529 !default;
$black-cutom:     #000 !default;
// scss-docs-start gray-color-variables

// scss-docs-start color-variables
$blue-custom:    #0d6efd !default;
$indigo-custom:  #6610f2 !default;
$purple-custom:  #6f42c1 !default;
$pink-custom:    #d63384 !default;
$red-custom:     #dc3545 !default;
$orange-custom:  #fd7e14 !default;
$yellow-custom:  #ffc107 !default;
$green-custom:   #198754 !default;
$teal-custom:    #20c997 !default;
$cyan-custom:    #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start theme-color-variables
$primary:       $gray-900-custom !default;
$secondary:     $gray-600-custom !default;
$success:       $green-custom !default;
$info:          $cyan-custom !default;
$warning:       $yellow-custom !default;
$danger:        $red-custom !default;
$light:         $gray-100-custom !default;
$dark:          $gray-900-custom !default;
// scss-docs-start theme-color-variables

// custom elements
$link-color: $gray-600-custom !default;

//@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
// @import '@angular/material/prebuilt-themes/indigo-pink.css';

//@import "node_modules/@costlydeveloper/ngx-awesome-popup/styles/theme";
@import "~@ng-select/ng-select/themes/material.theme.css";
@import '~pretty-checkbox/src/pretty-checkbox.scss';


html,body {
  margin:0;
  padding:0;
  height:100%; /* needed for container min-height */
}
body,
body *:not(i) { font-family: 'Archivo', sans-serif !important; }

.material-icons {
  font-family: 'Material Icons' !important;
}

// header
.mat-icon-button{
  width: 40px !important;
  height: 40px !important;
  margin-right:1em;
}
.mat-icon:last-child {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

#notifications {
  margin-right:15px;
}
@media (min-width: 1200px) {
  #notifications {
    margin-right:35px;
  }
}

.toolbar {
  box-shadow: 0px 7px 5px -9px rgba(0, 0, 0, 0.5) !important;
  z-index: 10;
  position: relative;
}

.breadcrumb {
  box-shadow: none !important;
}

// sidebar
.user-info {
  .name {
    font-size: 1em !important;
    font-weight: 700;
  }
  .rol {
    font-weight: normal !important;
    font-size: 0.9em;
  }
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: 'Archivo', sans-serif;
}

.sidebar-menu {
  .active {
    span {
      transform: scale(1) !important;
      font-size: 1em !important;
      font-weight: 600 !important;
    }
  }
}

.sidebar-wrapper .sidebar-dropdown > a::after {
  display: none !important;
}

.sidebar-wrapper .sidebar-dropdown > a.active::after {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  content: "\f104";
  font-style: normal;
  display: inline-block !important;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 10.5px !important;
  transform: none !important;
}

// taules
.dataTables_wrapper > div {
  margin-bottom: 1.5em;

  &:last-child {
    margin-bottom: 0;
  }
}

.table-sm > :not(caption) > * > *,
.table > :not(caption) > * > * {
  padding: 1.25rem 0.50rem;
}

.table th {
  padding: 0.50rem 0.50rem;
  border-bottom-width: 2px;
  border-color: #000;
}

.table tbody tr:nth-child(odd) {
  background-color:#f1f1f1;
}

// modals
.bg-gradient-custom {
  background: rgb(248,238,48) !important;
  background: -moz-linear-gradient(90deg, rgba(248,238,48,1) 12%, rgba(141,198,63,1) 65%,rgba(62,196,221,1) 100%) !important;
  background: -webkit-linear-gradient(90deg, rgba(248,238,48,1) 12%, rgba(141,198,63,1) 65%,rgba(62,196,221,1) 100%) !important;
  background: linear-gradient(90deg, rgba(248,238,48,1) 12%, rgba(141,198,63,1) 65%,rgba(62,196,221,1) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3ec4dd",endColorstr="#f8ee30",GradientType=1) !important;
}
.bg-gradient-custom *{
  color:#000 !important;
}
// app-confirmation-modal .mat-toolbar {
//   background: rgb(248,238,48,1);
//   background: -moz-linear-gradient(90deg, rgba(248,238,48,1) 12%, rgba(141,198,63,1) 65%,rgba(62,196,221,1) 100%);
//   background: -webkit-linear-gradient(90deg, rgba(248,238,48,1) 12%, rgba(141,198,63,1) 65%,rgba(62,196,221,1) 100%);
//   background: linear-gradient(90deg, rgba(248,238,48,1) 12%, rgba(141,198,63,1) 65%,rgba(62,196,221,1) 100%);
//   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3ec4dd",endColorstr="#f8ee30",GradientType=1);
// }
// app-confirmation-modal .mat-toolbar *{
//   color:#000 !important;
// }
// app-confirmation-modal .mat-toolbar .btn-danger {
//   background-color: $gray-600-custom !important;
// }

// custom indigo
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #000;
}

// tabs
.mat-tab-label, .mat-tab-link {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.mat-tab-label.mat-tab-label-active,
.mat-tab-link.mat-tab-label-active {
  color: rgb(0, 0, 0) !important;
  opacity: 1;
}

// devices detail
.main-box {
  border:none !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 0rem 0.5rem rgba(0, 0, 0, 0.2) !important;
  padding:1em !important;
  margin:0 !important;
}
.valor {
  font-size: 45px !important;
}

// greyscale
app-loading-custom,
.mat-slide-toggle-label {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}


.sidebar-content-footer{
  height: unset !important;
}


.pl-3{
  padding-left: 1rem !important;
}

body{
  margin: 0;
  color: black;
}
.modal-backdrop {
  /* bug fix - no overlay */
  display: none;
}
// .espace{
//   // margin-bottom: 58px;
//   min-height: calc(100% - 98px);
// }

@media (max-width: 600px) {
  .footer{
    position: fixed !important;
  }
}

  .mat-sidenav {
    height: calc(100% - 89px) !important;
    min-height: calc(100% - 89px) !important;
  }

  .mat-sidenav-content {
    height: calc(100% - 144px) !important;
    min-height: calc(100% - 144px) !important;
    padding-bottom: 40px !important;
  }

  .sidebar-footer {
    height: 54px !important;
    align-items: center !important;
  }


.c-pointer{
  cursor: pointer;
}
.c-pointer:hover{
  text-decoration: underline;
}

.c-pointer-i i{
  cursor: pointer;
}


/*
.row-equal-padding:first-child {
  padding-left: 15px;
  padding-right: 5px;
}

.row-equal-padding:last-child {
  padding-right: 15px;
  padding-left: 5px;
}*/
.custom-modalbox {
  overflow: auto;
  .dropdown-list {
    position: inherit !important;
  }
  mat-dialog-container {
    padding: 0;
  }
  mat-toolbar span {
    color: white;

    font-size: 1.15rem;

  }

  mat-toolbar{
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  }

  mat-dialog-container h2{
    display: block;
    padding: 15px;
  }

  .container{
    padding: 20px;
  }

}

.color-link{
  color: #05a !important;
}

.text-right{
  text-align: right !important;
}

.text-right-label{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;

  span{
      font-size: 15px;
      text-align: right;
  }
}

.h2withdiv{
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  a, h2{

      width: auto;
  }

  div{
      width: auto;

  }
}

.mat-badge-content{
  top: 2px;
  background: rgb(248,238,48,1);
  background: -moz-linear-gradient(90deg, rgba(248,238,48,1) 12%, rgba(141,198,63,1) 65%,rgba(62,196,221,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(248,238,48,1) 12%, rgba(141,198,63,1) 65%,rgba(62,196,221,1) 100%);
  background: linear-gradient(90deg, rgba(248,238,48,1) 12%, rgba(141,198,63,1) 65%,rgba(62,196,221,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3ec4dd",endColorstr="#f8ee30",GradientType=1);

}

.hover>tbody>tr:hover{
  background-color: #e5e6e5 !important;
}
td>a{
  text-decoration: none;
  color: black;
}

table>tbody>tr>.actions i , table>tbody>tr>.actions a{
  margin-left: 10px !important;
}

.selected-item{
  margin-top: 3px !important;
}

/* Estilos datatables */

.odd > .dataTables_empty{
  display: none !important;
}



.datatables_fix{
  overflow:auto !important;
  width:100% !important;
  position:relative !important;
}

/* Fin estilos datatables */

.page{

  display: inline-block !important;

  .group{
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;

  }
}

.dropup .multiselect-dropdown .dropdown-list {
  bottom: 110%;
  width: 100%;
  right: 0;
}

/* Para 600px */
@media only screen and (max-width: 620px) {
  .main-page{
    padding: 5px !important;
  }

  // app-block-dashboard{
  //   padding: 5px !important;
  // }

}



.p-absolute{
  position: absolute !important;
  left: 40%;transform: translate(-50%, 0);
}

 #SearchUserModalComponent, #DeviceEditQuickComponent, #DeviceModelAddQuickComponent{
  overflow-y: hidden !important;
 }


.tooltip-class{
  font-size: 16px !important;
}

// Selector de idioma
.language-selector-dropdown {
  position: relative;
  background-color: #e6e6e6;
  width: auto;
  float: left;
  max-width: 100%;
  border-radius: 5px;
  select {
    cursor: pointer;
    font-size: 1rem;
    max-width: 100%;
    padding: 0 30px 0 8px;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &:after {
    content: " ";
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 8px;
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #aaa;
  }
}

.date-picker {
  background-color: #f5f5f5;
  padding: 15px;
  height: 59px;
  border-bottom: 1px solid gray;
  align-items: center;
  display: flex;
  label {
    position: absolute;
    margin-top: -30px;
    font-size: 12px;
    color: gray;
  }
  input {
    background-color: #f5f5f5;
    border: none;
    margin: 15px -2px 0;
  }
  input:focus {
    outline: none;
  }
}

.date-picker.invalid {
  border-color: red;
  border-width: 2px;
  label {
    color: red;
  }
}

#hide-inputs {
  display: none;
}